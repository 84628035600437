import React from "react"
import PropTypes from "prop-types"
import styles from "./Bonus.module.scss"
import { List } from "components/typography/list"
import { Row, Column } from "components/grid"
import { SectionTitle } from "components/section"
import iconBonusPoints from "./assets/bonus_points.svg"
import { animate } from "utilities/animate"
import cn from "classnames"
import { htmlToReactChildren } from "../../../../utilities/htmlToReactChildren"

const Bonus = (props) => {
  const { points } = props
  const listItems = htmlToReactChildren(points)

  return (
    <Row>
      <Column sm="12" md="6">
        <div {...animate()}>
          <img
            className={styles.icon}
            src={iconBonusPoints}
            alt="Bonus points"
          />
        </div>
        <SectionTitle className={cn(styles.title, "serif", "h4")}>
          Bonus points
        </SectionTitle>
      </Column>
      <Column sm="12" md="6">
        <div {...animate()}>
          <List items={listItems} />
        </div>
      </Column>
    </Row>
  )
}

Bonus.propTypes = {
  points: PropTypes.string,
}

Bonus.defaultProps = {
  points: undefined,
}

export default Bonus
