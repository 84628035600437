import htmlToReact from "react-html-parser"
import React from "react"

export const htmlToReactChildren = (data) => {
  const el = htmlToReact(data)
  return React.Children.map(
    el[0]?.props.children,
    (child) => child.props.children
  )
}
