import React, { Component } from "react"
import animateScrollTo from "animated-scroll-to"
import { Layout } from "components/layout"
import { Meta } from "components/meta"
import { Hero } from "components/hero"
import { Section, SectionTitle } from "components/section"
import { ButtonLink } from "components/button"
import Benefits from "components/sections/careers-inner/benefits/Benefits"
import Bonus from "components/sections/careers-inner/bonus/Bonus"
import Points from "components/sections/careers-inner/points/Points"
import Apply from "components/sections/careers-inner/apply/Apply"
import { ThankYou } from "components/sections/careers-open-application/thank-you/ThankYou"
import { SkillsSection } from "../components/sections/careers-inner/skills/SkillsSection"
import { Careers } from "../constants/Careers"
import { htmlToReactChildren } from "../utilities/htmlToReactChildren"

class CareersInner extends Component {
  constructor(props) {
    super(props)

    this.state = {
      submitted: false,
    }

    this.applySectionRef = React.createRef()
  }

  scrollToApply = () => {
    animateScrollTo(this.applySectionRef.current, {
      maxDuration: 2000,
      cancelOnUserAction: false,
    })
  }

  handleScrollToApply = (event) => {
    event.preventDefault()
    this.scrollToApply()
  }

  handleToggleSubmitted = () => {
    this.setState({ submitted: !this.state.submitted })
    this.scrollToApply()
  }

  componentDidMount = () => {
    if (window.location.hash === "#apply") this.scrollToApply()
  }

  getApplyNowSectionOrder = (isOpenJobPosition, hasWorkInfoSection) => {
    if (isOpenJobPosition) {
      return undefined
    }

    if (hasWorkInfoSection) {
      return "05"
    }

    return "04"
  }

  isSectionValid = (data) => {
    return !!htmlToReactChildren(data)
  }

  render() {
    const { pageContext, location } = this.props
    const { submitted } = this.state
    const { job } = pageContext
    const isOpenJobPosition = Careers.OpenJobApplicationId === job.id

    const hasWorkInfoSection = !!this.isSectionValid(
      job.jobDescriptions.whatYouWillWorkOn
    )

    const applyNowSectionOrder = this.getApplyNowSectionOrder(
      isOpenJobPosition,
      hasWorkInfoSection
    )

    const heroProps = {
      subtitle: (
        <span
          dangerouslySetInnerHTML={{
            __html: job.jobDescriptions.description.trim(),
          }}
        />
      ),
      title: <span dangerouslySetInnerHTML={{ __html: job.name }} />,
      button: (
        <ButtonLink
          variant="primary"
          href="#apply"
          onClick={(event) => this.handleScrollToApply(event)}
        >
          Apply Now
        </ButtonLink>
      ),
    }

    const ogDescription =
      job.jobDescriptions.ogDescription ||
      "Ready to discover Mars? Click and apply for the job position."

    const seoDescription =
      job.jobDescriptions.metaDescription || job.jobDescriptions.description

    return (
      <Layout location={location}>
        <Meta
          title={`Careers - ${job.name}`}
          description={ogDescription}
          seoDescription={seoDescription}
        />
        <Hero {...heroProps} />

        {!isOpenJobPosition && (
          <>
            <SkillsSection skills={job.jobDescriptions.skills} />
            <Section background="dark">
              <Bonus points={job.jobDescriptions.bonusPoints} />
            </Section>
            <Section
              order="02"
              title="We're almost sold if you"
              padding={"small"}
            >
              <Points points={job.jobDescriptions.yourValues} />
            </Section>
            {hasWorkInfoSection && (
              <Section
                order="03"
                title="What you will work on"
                noTopSpacing
                padding={"bigBottomSmall"}
              >
                <Points points={job.jobDescriptions.whatYouWillWorkOn} />
              </Section>
            )}
            <Section
              order={hasWorkInfoSection ? "04" : "03"}
              title="What’s in it for you"
              background="dark"
            >
              <Benefits points={job.jobDescriptions.benefitsPoints} />
            </Section>
          </>
        )}

        <Section
          order={applyNowSectionOrder}
          title={!submitted ? "Apply now" : "Thank you"}
          innerRef={this.applySectionRef}
          padding={"bigTopSmall"}
        >
          <SectionTitle className={"serif"}>
            {!submitted ? "Your Turn!" : "Well done!"}
          </SectionTitle>

          {!submitted ? (
            <Apply
              jobId={job.id}
              positionName={job.name}
              handleToggleSubmitted={this.handleToggleSubmitted}
            />
          ) : (
            <ThankYou />
          )}
        </Section>
      </Layout>
    )
  }
}

export default CareersInner
