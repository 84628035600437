import { useCallback, useState } from "react"

export const useFileState = () => {
  const [fileState, setFileState] = useState([])
  const findFile = (filename) => {
    return fileState.some((file) => file.name === filename)
  }

  const addFile = (file) => {
    if (!findFile(file.name)) {
      setFileState((prevFileState) => [...prevFileState, file])
    }
  }

  const removeFile = (filename) => {
    if (findFile(filename)) {
      setFileState((prevFileState) =>
        prevFileState.filter((file) => file.name !== filename)
      )
    }
  }

  const getAllFilesSize = useCallback(() => {
    return fileState.reduce((acc, file) => {
      return acc + file.size
    }, 0)
  }, [fileState])

  return {
    fileState,
    setFileState,
    findFile,
    addFile,
    removeFile,
    getAllFilesSize,
  }
}
