import React from "react"
import cx from "classnames"
import styles from "./Apply.module.scss"
import { CareersForm } from "components/forms/careers-form/CareersForm"
import { animate } from "utilities/animate"
import PropTypes from "prop-types"
import PersonalInfo from "../../../personal-info/PersonalInfo"
import HanaImg from "./assets/hana-greta-matkovic.jpg"

const Apply = (props) => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.info}>
        <p className={styles.paragraph} {...animate()}>
          Fill out the short application form to apply for the job position.
          Usually, we respond within two&nbsp;weeks.
        </p>

        <p
          className={cx(styles.paragraph, styles.smallParagraph, "small")}
          {...animate()}
        >
          Some tips: keep it short and focused – we’d rather hear about your
          skills or problems you solved by thinking outside of the box. Also –
          let us know why you would consider to join our team and what we can
          expect from your side. If you have questions, feel free to reach out
          to Hana Greta.
        </p>
        <div {...animate()}>
          <PersonalInfo
            name={"Hana Greta Matković"}
            email={"people@martian.ventures"}
            linkedIn={"https://www.linkedin.com/in/hanagreta/"}
            position={"Chief of Staff"}
            image={{
              src: HanaImg,
              alt: "Hana Greta Matković",
            }}
          />
        </div>
      </div>
      <div className={styles.form} {...animate()}>
        <CareersForm
          jobId={props.jobId}
          positionName={props.positionName}
          handleToggleSubmitted={props.handleToggleSubmitted}
        />
      </div>
    </div>
  )
}

Apply.propTypes = {
  jobId: PropTypes.string,
  positionName: PropTypes.string,
}

Apply.defaultProps = {
  jobId: undefined,
  positionName: undefined,
}

export default Apply
