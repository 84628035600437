import React from "react"
import PropTypes from "prop-types"
import { List } from "components/typography/list"
import { Row, Column } from "components/grid"
import { animate } from "utilities/animate"
import { htmlToReactChildren } from "../../../../utilities/htmlToReactChildren"

const Skills = (props) => {
  const { points } = props

  const listItems = htmlToReactChildren(points)

  return (
    <div {...animate()}>
      <Row>
        <Column sm="12" md="6">
          <List items={listItems.slice(0, Math.ceil(listItems.length / 2))} />
        </Column>
        <Column sm="12" md="6">
          <List items={listItems.slice(Math.ceil(listItems.length / 2))} />
        </Column>
      </Row>
    </div>
  )
}

Skills.propTypes = {
  points: PropTypes.string,
}

Skills.defaultProps = {
  points: undefined,
}

export default Skills
