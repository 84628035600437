import React from "react"
import PropTypes from "prop-types"
import styles from "./Benefits.module.scss"
import { List } from "components/typography/list"
import { Row, Column } from "components/grid"
import { animate } from "utilities/animate"
import iconProduct from "./assets/product.svg"
import iconSchedule from "./assets/schedule.svg"
import iconValue from "./assets/value.svg"
import cn from "classnames"
import { htmlToReactChildren } from "../../../../utilities/htmlToReactChildren"

const Benefits = (props) => {
  const { points } = props
  const listItems = htmlToReactChildren(points)

  return (
    <>
      <div {...animate()}>
        <Row>
          <Column sm="12" md="6">
            <List items={listItems.slice(0, Math.ceil(listItems.length / 2))} />
          </Column>
          <Column sm="12" md="6">
            <List items={listItems.slice(Math.ceil(listItems.length / 2))} />
          </Column>
        </Row>
      </div>
      <div className={styles.slider}>
        <div className={styles.columns}>
          <div className={styles.column} {...animate({ delay: 100 })}>
            <div className={styles.icon}>
              <img src={iconProduct} alt="Product" />
            </div>
            <h4 className={cn(styles.title, "serif")}>
              Drive the big change, grow,&nbsp;be&nbsp;curious
            </h4>
            <p className={styles.text}>
              Create digital products with powerful&nbsp;companies.
            </p>
          </div>
          <div className={styles.column} {...animate({ delay: 200 })}>
            <div className={styles.icon}>
              <img src={iconSchedule} alt="Schedule" />
            </div>
            <h4 className={cn(styles.title, "serif")}>
              Manage your own schedule
            </h4>
            <p className={styles.text}>
              Manage your own time and be available to the team, and we don’t
              care if you’re not a morning&nbsp;person.
            </p>
          </div>
          <div className={styles.column} {...animate({ delay: 300 })}>
            <div className={styles.icon}>
              <img src={iconValue} alt="Value" />
            </div>
            <h4 className={cn(styles.title, "serif")}>Value-based income</h4>
            <p className={styles.text}>
              Grow, add value and we’ll be happy to compensate for
              it&nbsp;appropriately.
            </p>
          </div>
        </div>
      </div>
    </>
  )
}

Benefits.propTypes = {
  points: PropTypes.string,
}

Benefits.defaultProps = {
  points: undefined,
}

export default Benefits
