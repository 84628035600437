import React from "react"
import * as PropTypes from "prop-types"
import { Section, SectionTitle } from "../../../section"
import Skills from "./Skills"
import styles from "./SkillsSection.module.scss"

export function SkillsSection(props) {
  return (
    <Section
      order="01"
      title="Your must-have skills / experience"
      background={"transparent"}
      className={styles.wrapper}
      padding={"bigBottomSmall"}
    >
      <SectionTitle>
        If you want us to be sold entirely, you’ve got to have the following
        skills to be&nbsp;considered.
      </SectionTitle>
      <Skills points={props.skills} />
    </Section>
  )
}

SkillsSection.propTypes = { skills: PropTypes.string }
