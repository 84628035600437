import React from "react"
import { List } from "components/typography/list"
import { Row, Column } from "components/grid"
import { animate } from "utilities/animate"
import PropTypes from "prop-types"
import { htmlToReactChildren } from "../../../../utilities/htmlToReactChildren"

const Points = ({ points }) => {
  const listItems = htmlToReactChildren(points)

  return (
    <div {...animate()}>
      <Row>
        <Column sm="12" md="6">
          <List items={listItems?.slice(0, Math.ceil(listItems.length / 2))} />
        </Column>
        <Column sm="12" md="6">
          <List items={listItems?.slice(Math.ceil(listItems.length / 2))} />
        </Column>
      </Row>
    </div>
  )
}

Points.propTypes = {
  points: PropTypes.string,
}

Points.defaultProps = {
  points: undefined,
}

export default Points
