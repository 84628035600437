import React, { useState } from "react"
import { useForm } from "react-hook-form"
import axios from "axios"
import { objectToFormData } from "object-to-formdata"
import { Row, Column } from "components/grid/Grid"
import { Input, Dropzone, Checkbox, Error } from "components/form/Form"
import { Button } from "components/button"
import PropTypes from "prop-types"
import { useFileState } from "../../../hooks/useFileState"
import cx from "classnames"
import { useBreakpoint } from "../../../hooks/useBreakpoint"
import iconCoverLetter from "./assets/cover-letter.svg"
import iconPortfolioCv from "./assets/portfolio-cv.svg"
import styles from "./CareersForm.module.scss"

const maxLengthError = (charCount) =>
  `Maximum length of ${charCount} characters exceeded.`

// 60MB
const maxSizeLimit = 60 * 1024 * 1024

export const CareersForm = (props) => {
  const { register, handleSubmit, errors } = useForm()
  const breakpoint = useBreakpoint()
  const isButtonFull = breakpoint === "xs"

  const [formState, setFormState] = useState({
    submitting: false,
    submitted: false,
    status: null,
  })

  const {
    fileState: cvFileState,
    removeFile: removeCvFile,
    addFile: addCvFile,
    getAllFilesSize: getAllCvFilesSize,
  } = useFileState()

  const {
    fileState: coverLetterFileState,
    removeFile: removeCoverLetterFile,
    addFile: addCoverLetterFile,
    getAllFilesSize: getAllCoverLetterFilesSize,
  } = useFileState()

  const handleServerResponse = (ok, msg) => {
    setFormState({
      submitting: false,
      status: { ok, msg },
    })
    if (ok) {
      props.handleToggleSubmitted()
    }
  }

  const onSubmit = (values) => {
    if (cvFileState.length === 0) {
      return
    }

    const allFilesSize = getAllCvFilesSize() + getAllCoverLetterFilesSize()
    if (allFilesSize > maxSizeLimit) {
      setFormState({
        submitting: false,
        status: {
          ok: false,
          msg: "The sum of all files must not exceed 60MB.",
        },
      })
      return
    }

    const formData = objectToFormData(values)
    // remove if empty
    if (!formData.get("webLink")) {
      formData.delete("webLink")
    }
    formData.append("jobPositionId", props.jobId)
    // redundant field for API
    formData.delete("privacy_policy")

    cvFileState.map((file) => {
      return formData.append(`cv`, file)
    })

    coverLetterFileState.map((file) => {
      return formData.append(`coverLetter`, file, file.name)
    })

    if (values.privacy_policy) {
      setFormState({ submitting: true })

      axios({
        method: "POST",
        url: "https://mailhook.martian.services/jobApply",
        data: formData,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
        .then(() => {
          handleServerResponse(true)

          window?.dataLayer?.push({
            event: "successful_job_application",
            positionName: props?.positionName,
          })
        })
        .catch((r) => {
          const message =
            r?.response?.data?.error?.message ||
            "Unknown error occurred - try again or contact people@martian.ventures"
          handleServerResponse(false, message)
        })
    }
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)} className={styles.form}>
      <Row>
        <Column sm="12">
          <div className={styles.field}>
            <Input
              name="firstName"
              type="text"
              placeholder="First name"
              innerRef={register({
                required: "Please fill in this field.",
                maxLength: { value: 255, message: maxLengthError(255) },
              })}
              error={errors.firstName && errors.firstName.message}
            />
          </div>
        </Column>

        <Column sm="12">
          <div className={styles.field}>
            <Input
              name="lastName"
              type="text"
              placeholder="Last name"
              innerRef={register({
                required: "Please fill in this field.",
                maxLength: {
                  value: 255,
                  message: maxLengthError(255),
                },
              })}
              error={errors.lastName && errors.lastName.message}
            />
          </div>
        </Column>

        <Column sm="12">
          <div className={styles.field}>
            <Input
              name="email"
              type="text"
              placeholder="E-mail"
              innerRef={register({
                required: "Please fill in this field.",
                maxLength: {
                  value: 255,
                  message: maxLengthError(255),
                },
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,15}$/i,
                  message: "Invalid email address.",
                },
              })}
              error={errors.email && errors.email.message}
            />
          </div>
        </Column>

        <Column sm="12">
          <div className={styles.field}>
            <Input
              name="startingDate"
              type="text"
              placeholder="Earliest starting date"
              innerRef={register({
                required: "Please fill in this field.",
                maxLength: {
                  value: 100,
                  message: maxLengthError(100),
                },
              })}
              error={errors.startingDate && errors.startingDate.message}
            />
          </div>
        </Column>

        <Column sm="12">
          <div className={styles.field}>
            <Input
              name="salary"
              type="text"
              placeholder="Expected salary"
              innerRef={register({
                required: "Please fill in this field.",
                maxLength: {
                  value: 255,
                  message: maxLengthError(255),
                },
              })}
              error={errors.salary && errors.salary.message}
            />
          </div>
        </Column>

        <Column sm="12">
          <div className={styles.field}>
            <Input
              name="webLink"
              type="text"
              placeholder="Web link (optional)"
              innerRef={register({
                maxLength: {
                  value: 255,
                  message: maxLengthError(255),
                },
              })}
              error={errors.webLink && errors.webLink.message}
            />
          </div>
        </Column>
      </Row>
      <div className={cx(styles.field, styles.dropzone)}>
        <Dropzone
          name="cv"
          addFile={addCvFile}
          removeFile={removeCvFile}
          files={cvFileState}
          placeholder={
            <span className={styles.dropzonePlaceholder}>
              <span className={styles.dropzonePlaceholderText}>
                Upload your portfolio &amp; CV here
              </span>
              <span className={styles.dropzoneAcceptedFiles}>
                (.pdf, .jpg and .png supported)
              </span>
            </span>
          }
          uploadIcon={iconPortfolioCv}
        />
        <Error
          error={
            formState.submitted && cvFileState.length === 0
              ? "Please add a file."
              : false
          }
        />
      </div>
      <div className={cx(styles.field, styles.dropzone)}>
        <Dropzone
          name="coverLetter"
          addFile={addCoverLetterFile}
          removeFile={removeCoverLetterFile}
          files={coverLetterFileState}
          placeholder={
            <span className={styles.dropzonePlaceholder}>
              <span className={styles.dropzonePlaceholderText}>
                Upload your cover letter here
              </span>
              <span className={styles.dropzoneAcceptedFiles}>
                (.pdf, .jpg and .png supported)
              </span>
            </span>
          }
          uploadIcon={iconCoverLetter}
        />
      </div>
      <div className={styles.field}>
        <Checkbox
          id="privacy_policy"
          name="privacy_policy"
          type="checkbox"
          label={
            <>
              I agree to{" "}
              <a
                href="/privacy-policy/"
                target="_blank"
                rel="noopener noreferrer"
                className="text-primary"
              >
                Privacy Policy
              </a>
            </>
          }
          innerRef={register({ required: "Please accept." })}
          error={errors.privacy_policy && errors.privacy_policy.message}
        />
      </div>
      <div className={styles.field}>
        <Button
          variant="primary"
          type="submit"
          disabled={formState.submitting}
          onClick={() => setFormState({ submitted: true })}
          full={isButtonFull}
        >
          {formState.submitting ? "Submitting..." : "Submit application"}
        </Button>
      </div>
      <div className={styles.field}>
        {formState.status && !formState.status.ok && (
          <p className={styles.error}>{formState.status.msg}</p>
        )}
      </div>
    </form>
  )
}

CareersForm.propTypes = {
  jobId: PropTypes.string,
  positionName: PropTypes.string,
}

CareersForm.defaultProps = {
  jobId: undefined,
  positionName: undefined,
}
