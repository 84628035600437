import React from "react"
import PropTypes from "prop-types"
import styles from "./List.module.scss"

export const OrderedList = props => (
  <ol className={styles.orderedList}>{props.children}</ol>
)

OrderedList.propTypes = {
  children: PropTypes.node,
}

OrderedList.defaultProps = {
  children: null,
}
