/* eslint-disable  jsx-a11y/img-redundant-alt */
import React from "react"
import PropTypes from "prop-types"
import cn from "classnames"
import styles from "./PersonalInfo.module.scss"
import iconLetter from "../../assets/icons/letter.svg"
import iconLinkedIn from "../../assets/icons/linkedin.svg"

const PersonalInfo = ({
  className,
  image,
  name,
  email,
  linkedIn,
  position,
}) => {
  return (
    <div className={cn(className)}>
      <img className={styles.avatar} src={image?.src} alt={image?.alt} />
      <h4 className={"serif"}>{name}</h4>
      <p className={cn(styles.position, "small")}>{position}</p>
      <div className={styles.links}>
        <a
          href={`mailto:${email}`}
          target={"_blank"}
          rel={"noopener noreferrer"}
          className={styles.link}
        >
          <img src={iconLetter} alt="E-mail" />
        </a>
        <a
          href={linkedIn}
          target={"_blank"}
          rel={"noopener noreferrer"}
          className={styles.link}
        >
          <img src={iconLinkedIn} alt="LinkedIn" />
        </a>
      </div>
    </div>
  )
}

PersonalInfo.propTypes = {
  className: PropTypes.string,
  image: PropTypes.shape({
    src: PropTypes.string,
    alt: PropTypes.string,
  }),
  name: PropTypes.string,
  position: PropTypes.string,
  email: PropTypes.string,
  linkedIn: PropTypes.string,
}

PersonalInfo.defaultProps = {
  className: "",
  image: undefined,
  name: undefined,
  position: undefined,
  email: undefined,
  linkedIn: undefined,
}

export default PersonalInfo
