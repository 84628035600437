import React from "react"
import PropTypes from "prop-types"
import styles from "./List.module.scss"

export const UnorderedList = ({ children, ...rest }) => (
  <ul className={styles.unorderedList} {...rest}>
    {children}
  </ul>
)

UnorderedList.propTypes = {
  children: PropTypes.node,
}

UnorderedList.defaultProps = {
  children: null,
}
