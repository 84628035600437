import React from "react"
import PropTypes from "prop-types"

import { UnorderedList } from "./UnorderedList"
import { OrderedList } from "./OrderedList"
import { ListItem } from "./ListItem"

export const List = (props) => {
  if (props.type === "unordered") {
    const { items } = props

    const ListComponent =
      props.type === "unordered" ? UnorderedList : OrderedList

    return (
      <ListComponent>
        {items.map((item, index) => (
          <ListItem key={index}>{item}</ListItem>
        ))}
      </ListComponent>
    )
  }
}

List.propTypes = {
  type: PropTypes.string,
  items: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.node])
  ),
}

List.defaultProps = {
  type: "unordered",
  items: [],
}
