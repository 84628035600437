import React from "react"
import PropTypes from "prop-types"
import styles from "./List.module.scss"

export const ListItem = props => {
  const { children } = props

  return <li className={styles.listItem}>{children}</li>
}

ListItem.propTypes = {
  children: PropTypes.node,
}

ListItem.defaultProps = {
  children: null,
}
