import React from "react"
import styles from "./ThankYou.module.scss"

export const ThankYou = (props) => {
  return (
    <div className={"h5"}>
      <p className={styles.paragraph}>
        Your application has been sent out to Mars successfully. You should have
        a confirmation email in your inbox.
      </p>
      <p className={styles.paragraph}>
        Fingers crossed it’ll work out with us :)
      </p>
    </div>
  )
}
